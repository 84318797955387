<template>
  <div class="login">
    <el-container>
      <el-main class="loginBg">
        <div class="left_ad">
          <img rount-link class="logo" alt="Vue logo" src="@/assets/bg.png" />
        </div>
      </el-main>
      <el-aside
        :class="
          (showFlag && !mobileErrors ) || (!showFlag && !mobileErrors)
            ? 'loginBox'
            : 'loginBox autoheight'
        "
      >

         <h1>欢迎登录3A医药城</h1>
        <div class="login_title">
              <!-- <el-divider direction="vertical"></el-divider> -->
              <span :class="showFlag ? 'cursorBox active' : 'cursorBox '" @click="tabFun()">账号密码登录</span>
               / <span :class="showFlag ? 'cursorBox ' : 'cursorBox active'" @click="tabFun()">验证码登录</span>
        </div>
        <div class="login_content" v-show="showFlag">
          <div :class="!mobileErrors? 'panel': 'panel autoheight'">
            <div class="input-group">
              <span class="input-group-addon">
                <!-- <strong class="el-icon-user"></strong> -->
                <img src="../assets/userIcon.png" alt="">
              </span>
              <input
                type="text"
                class="mobile btn"
                placeholder="用户账号"
                v-model="mobile"
                @input="validatePhone"
              />
            </div>
            <p class="error-tip">
              <strong  v-show="mobileErrors" class="el-icon-warning"></strong>{{mobileErrors}}
            </p>
          </div>
          <div  :class="!inpErrors? 'panel': 'panel autoheight'">
            <div class="input-group">
              <span class="input-group-addon">
                <!-- <strong class="el-icon-lock"></strong> -->
                <img src="../assets/lockIcon.png" alt="">
              </span>
              <input
                :type="eyeVal"
                class="password btn"
                placeholder="登录密码"
                v-model="password"
                 @input="validateInp"
              />
              <span  class="eyeBtn" @click="eyeChange">
                <img v-if="EyeFlag" src="../assets/eyeClose.png" alt="">
                <img v-else src="../assets/eye.png" alt="">
              </span>
            </div>
            <p class="error-tip">
              <strong  v-show="inpErrors" class="el-icon-warning"></strong>{{inpErrors}}
            </p>
          </div>
           <div class="panel jumpBox">
            <a class="text text-right cursorBox" @click="jumpRegister">立即注册</a>
            <div>
               <el-checkbox v-model="checkMsgFlag" laber=''  name="type"  @change="RememberMsgChange"></el-checkbox>
              <a class="text text-left RememberMsg" >记住账号密码</a>
            </div>
            <div class="clear"></div>
          </div>
          <div class="panel btnBox">
            <div
              :class="!mobile || !password ? 'loginBtn disabled' : 'loginBtn'"
              @click="login"
            >
              登录
            </div>
          </div>
        </div>
        <div class="login_content" v-show="!showFlag">
          <div :class="!mobileErrors? 'panel': 'panel autoheight'">
            <div class="input-group">
              <span class="input-group-addon">
                <!-- <strong class="el-icon-user"></strong> -->
                <img src="../assets/userIcon.png" alt="">
              </span>
              <input
                type="mobile"
                class="mobile btn"
                placeholder="手机号码"
                v-model="vscodemobile"
                @input="validatePhone"
              />
            </div>
            <p class="error-tip">
              <strong  v-show="mobileErrors" class="el-icon-warning"></strong>{{mobileErrors}}
            </p>
          </div>
          <div :class="!inpErrors? 'panel': 'panel autoheight'">
            <div class="input-group">
              <span class="input-group-addon">
                <!-- <strong class="el-icon-lock"></strong> -->
                <img src="../assets/lockIcon.png" alt="">
              </span>
              <input
                ref="vscodeInp"
                type="password"
                class="vscodeInp btn"
                placeholder="短信验证码"
                v-model="vscode"
                  @input="validateInp"
              />
              <button
                ref="child"
                class="vscodeBtn"
                :class="{ disabled: !this.canClick }"
                @click="countDown"
              >
                {{ content }}
              </button>
            </div>
            <p class="error-tip">
              <strong  v-show="inpErrors" class="el-icon-warning"></strong>{{inpErrors}}
            </p>
          </div>
          <div class="panel jumpBox">
            <a class="text text-right cursorBox" @click="jumpRegister">立即注册</a>
            <!-- <a class="text text-right">忘记密码</a> -->
            <div class="clear"></div>
          </div>
          <div class="panel btnBox">
            <div
              :class="
                !vscodemobile || !vscode ? 'loginBtn disabled' : 'loginBtn'
              "
              @click="vscodelogin"
            >
              登录
            </div>
          </div>
        </div>
        <div class="panel jumpAgreementBox">
          <!-- <el-checkbox v-model="checkFlag" laber=''  name="type"  @change="handleCheckAllChange"></el-checkbox> -->
          登录即表示我同意
          <a class="text text-left" @click="jumpAgreement"> 
            《用户协议》
          </a>和
          <a class="text text-left" @click="jumpUserAgreement"> 
            《隐私政策》
          </a>
          <!-- <div class="VisitorLogin" @click="VisitorLoginFun">游客模式</div> -->
        </div>
      </el-aside>
    </el-container>

    <el-dialog
      class="AlertBox" 
      title="欢迎登录3A医药城"
      :visible.sync="centerDialogVisible"
      width="34%"
      center>
        <span class="alertMsg">尊敬的用户您好，为了给您提供更好的购物服务，3A医药城将会获取您在{{FirstAuth}}中提供的资质信息，请认真阅读《用户协议和隐私政策》；您同意并接受后就可以获得我们的服务。</span>
        <p class="alertP">授权资质包括以下:</p>
        <el-row>
          <el-col :span="12">
              <div class="bodyTit">
                基本信息
              </div>
              <p class="bodyMsg">包含企业类型、经营区域和经营范围等信息。</p>
          </el-col>
          <el-col :span="12">
              <div class="bodyTit">
                工商信息
              </div>
              <p class="bodyMsg">包含企业工商信息、组织机构代码证和税务登记证等信息。</p>
          </el-col>
          <el-col :span="12">
              <div class="bodyTit">
                资质信息
              </div>
              <p class="bodyMsg">包含药店经营许可证和医疗器械相关证件等信息。</p>
          </el-col>
          <el-col :span="12">
              <div class="bodyTit">
                采购信息
              </div>
              <p class="bodyMsg">包含开票信息、采购员信息和委托书等信息。</p>
          </el-col>
        </el-row>
      <span slot="footer" class="dialog-footer">
        <p>
          <span class="sureBtn" @click="sureFun">申请授权</span>
        </p>
        <p  class="cancelBtn" @click="centerDialogVisible = false">暂不申请</p>
      </span>
    </el-dialog>
     <el-dialog
      class="AuthorBox" 
      :title="AuthorTitle"
      :visible.sync="AuthorDialogVisible"
      width="34%"
      center>
      <div class="PicBox">
        <img src="@/assets/undrawReviewedDocs.png" alt="">
      </div>
      <p class="PicMsg">我们的客服正在抓紧审核您的资质，您也可以通过电话联系专属客服来加快审核进度；当审核完成后您就可以获得我们平台全部服务。</p>
      <p class="kefu">
        <em><img src="@/assets/kefu.png" alt="客服"></em>
        <span>专属客服</span>
      </p>
      <p class="AuthorMsg">
        <!-- <span>姓名:{{customName}}</span> -->
        <span>电话：{{customPhone}}</span>
      </p>
     <p class="PicMsgs">*为提高 <span>广东、海南</span> 会员发货效率，<span>广东、海南</span> 会员可直接在 <a href="https://yaobaiwan.com/#/login">yaobaiwan.com </a>使用当前账号登录。</p>
      <div class="clear"></div>
    </el-dialog>

  </div>
</template>
<style lang="less" scoped>
/deep/.el-dialog{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:40%;
  left:50%;
  transform:translate(-50%,-50%);
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
}
.clear{
  clear: both;
}
/deep/.AlertBox{
  .el-dialog{
    border-radius:10px;
  }
  .el-dialog__header{
    color: #333333;
    font-weight: bold;
    font-size: 24px;
    .el-dialog__headerbtn{
        display: none;
        background: #fff;
        color: #908181;
        width: 30px;
        height: 30px;
        font-weight: bold;
        border-radius: 50%;
        right: -30px;
        top: -15px;
        .el-dialog__close{
            font-weight: bolder;
            color: #908181;
            line-height: 30px;
            font-size: 24px;
        }
    }
  }
  .el-dialog__body{
     padding: 25px 45px 30px;
      .alertMsg{
        font-size: 13px;
      }
      .alertP{
        margin-top:20px;
        color: #39393D;
        font-size: 13px;
        font-weight: bold;
      }
      .bodyTit{
        text-align: left;
        color: #1D1D1E;
        font-size: 14px;
        font-weight: bold;
        margin-top:10px;
      }
      .bodyMsg{
          color: #999999;
          font-size: 12px;
          margin-top:4px;
          span{
            color: #39393D;
          }
      }
     
  }
  .el-dialog__footer{
       .sureBtn{
        display: block;
        width: 220px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-image: linear-gradient(to right, #F67433 , #F42929);
        margin:0 auto 10px;
        color: #fff;
        border-radius: 5px;
      }
      .cancelBtn{
          color: #999999;
          text-align: center;
          line-height: 40px;
      }
  }
}

/deep/.AuthorBox{
   .el-dialog{
    border-radius:10px;
  }
  .el-dialog__header{
    color: #333333;
    font-weight: bold;
    font-size: 24px;
    .el-dialog__headerbtn{
        display: none;
        background: #fff;
        color: #908181;
        width: 30px;
        height: 30px;
        font-weight: bold;
        border-radius: 50%;
        right: -30px;
        top: -15px;
        .el-dialog__close{
            font-weight: bolder;
            color: #908181;
            line-height: 30px;
            font-size: 24px;
        }
    }
  }
  .el-dialog__body{
    padding: 25px 45px 30px;
    .PicBox{
      img{
        margin: 0 auto;
        width: 30%;
        height: auto;
        display: block;
        margin-bottom: 20px;
      }
    }
    .PicMsg{
      font-size: 14px;
      color: #39393D;
      margin-bottom:26px;
    }
     .PicMsgs{
      font-size: 14px;
      color: #39393D;
      color: #ccc;
      span{
          color: #F42929;
      }
      a{
           color: #000;
           &:hover{
             color: #F42929;
           }
      }
    }
    .kefu{
      width:90px;
      font-size: 13px;
      color: #666666;
      text-align: right;
      margin:34px auto 0;
      em{
        display: inline-block;
        padding-top: 5px;
        width: 20px;
        height: 20px;
        float: left;
        img{
          width: 100%;
          height: 100%;
        }
      }
      span{
        display: inline-block;
        height: 30px;
        line-height: 30px;
        font-weight: bold;
        color: #39393D;
        font-size: 16px;
      }
    }
    .AuthorMsg{
      color: #39393D;
      font-size: 14px;
      margin:20px auto;
      text-align: center;
      // width: 250px;
      // span:nth-child(1){
      //   float: left;
      // }
      // span:nth-child(2){
      //   float: right;
      // }
    }
  }

}
.cursorBox{
  cursor: pointer;
}
.el_main {
  background: red;
}
.jumpAgreementBox{
   font-size: 12px;
   text-align: left;
   color: #666666;
   padding-left:3px;
   line-height: 40px;
   .text {
     // margin-right: 5px;
    color: #6c6c6c;
    font-size: 12px;
    cursor: pointer;
    &:hover{
      color: red;
    }
  }
  .VisitorLogin{
    float: right;
    font-size: 12px;
      cursor: pointer;
          line-height: 21px;
      &:hover{
        color: red;
      }
  }
}
.btnBox{
   margin-bottom: 10px!important;
}
.jumpBox {
  // padding: 0 30px;
  margin-bottom: 10px!important;
  .clear{
    clear: both;
  }
  .RememberMsg{
    margin-top:3px;
  }
  .text-right {
    text-align: right;
    float: right;
    color: #ed4345!important;
  }
  .text-left {
    text-align: left;
    float: left;
  }
  .text {
    margin-right: 5px;
    color: #6c6c6c;
    font-size: 12px;
  }
  /deep/.el-checkbox__label{
     font-size: 12px;
  }
}

.login {
  background: radial-gradient(#FF073C,#FF6700  ,#FD2927);
  .el-container{
    width: 1200px;
    margin:0 auto;
    padding:94px 0;
  }
  .loginBg {
    display: table;
    width:768px;
    padding: 0;
    height: 100%;
    .left_ad {
      display: table-cell;
      overflow: hidden;
      img {
        width:768px;
        height:583px;
      }
    }
  }
  .loginBox {
    width:432px !important;
    padding: 30px;
    height:583px;
    h1{
      line-height:52px;
      text-align: center;
      font-size:34px;
      margin-bottom: 10px;
    }
    &.autoheight {
      // height: 360px;
    }
    background-color: #ffffff;
    .error-tip {
      height:30px;
      .el-icon-warning {
        font-size: 16px;
        margin-right: 5px;
      }
      line-height:30px;
      font-size: 13px;
      color: #ed4345;
    }
    .login_title {
      line-height: 58px;
      // border-bottom: 2px solid #E6E6E6;
      .el-divider--vertical{
        padding:0;
        margin: 0;
      }
      span {
        // font-weight: bold;
        height:38px;
        line-height:38px;
        display: inline-block;
        text-align: center;
        font-size:18px;
        &.active {
          border-bottom: 2px solid #FF3333;
          color: #FF3333;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
    .login_content {
      padding:16px 0px 0 0px;
      .panel {
        width: 100%;
        //  margin-bottom:10px;
        &.autoheight{
          margin-bottom: 0px;
        }
        .loginBtn {
          border:1px solid #ed4345;
          border-radius:30px;
          background: #ed4345;
          margin-top:90px;
          color: #fff;
          width: 100%;
          height: 40px;
          text-align: center;
          line-height: 40px;
          display: block;
          cursor: pointer;
        }
        .loginBtn.disabled {
          background-color: #ffcccc;
          border-color:#ffcccc;
          color:#ffffff;
          cursor: not-allowed; // 鼠标变化
        }
        .input-group {
          position: relative;
          display: table;
          border-collapse: separate;
          height:48px;
          padding: 0;
          border: 1px solid#f1eeee;
          border-radius:10px;
          width: 100%;
          font-size:20px;
          background: #fff;
          .eyeBtn{
            width: 48px;
            height:48px;
            position: absolute;
            right: 0;
            z-index: 9;
            cursor: pointer;
            img{
              width:26px;
              padding-top:10px;
            }
          }
          .input-group-addon {
            color: #fff;
            background: #F3F5F7FF;
            float: left;
            width: 48px;
            height:48px;
            font-size: 24px;
            line-height: 38px;
            text-align: center;
            border-bottom-left-radius:10px;
            border-top-left-radius:10px;
            img{
              width:26px;
              padding-top:10px;
            }
          }
          input:-webkit-autofill{
            -webkit-box-shadow:0 0 0 1000px white inset !important;
            font-size:20px !important;
          }
          input {
            text-indent: 10px;
            height:48px;
            width: calc(100% - 48px);
            background: #fff;
            padding: 0;
            border: none;
            border-radius:12px;
            float: left;
            font-size:20px !important;
          }
          .vscodeInp {
            text-indent: 10px;
            height:48px;
            padding: 0;
            border: none;
            border-radius: 0;
            float: left;
            width: calc(100% - 150px);
          }
          .vscodeBtn {
            float: left;
            width: 100px;
            height:48px;
            font-size: 12px;
            line-height:48px;
            text-align: center;
            border: none;
            background: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
<script>
import { Login ,vscodeLogin,sendCode ,userAuth,firstAuth} from "api/home.js";
import { mapMutations } from "vuex";
// ,removeLocalToken
import { setToken, removeToken,getToken,setLocalToken } from "utils/session.js";
import Qs from "qs";
import Cookies from 'js-cookie';
import { MemberInfo } from "api/member.js";
export default {
  name: "Register",
  components: {},
  data() {
    return {
       inject:['reload'],
        centerDialogVisible: false,
        AuthorDialogVisible:false,
        AuthorTitle:'',
        // customName:'',
        customPhone:'',
       checkMsgFlag:true,//记住账号
      // checkFlag:true,
      EyeFlag:true,
      eyeVal:'password',
      checkFunFlag:true,
      mobile: "",
      password: "",
      showFlag: true,
      vscodemobile: "",
      vscode: "",
      // 点击倒计时
      content: "获取手机验证码", // 按钮里显示的内容
      totalTime: 60, //记录具体倒计时时间
      canClick: false, //添加canClick
      mobileErrors:'',
      inpErrors:'',
      FunFlag:false,
      FirstAuth:''  //// 查询账号首次授权平台
    };
  },
  watch: {
    outerVisible() {
    this.reload();

    },
  },
  created() {
     localStorage.clear();
    sessionStorage.clear()
     if (Cookies.get('wyuserMobile')) {
        this.mobile=Cookies.get('wyuserMobile',);
        this.password=Cookies.get('wyuserPassword');
        this.showFlag=true
     }
    let domainStr=document.domain.split('.').slice(-2).join('.')
    if(domainStr!='3akx.cn'){
          Cookies.remove('refresh_token')
          Cookies.remove('memberName')
          Cookies.remove('access_token')
    }else{
        Cookies.remove('refresh_token',{expires:null,path:'/',domain:domainStr})
        Cookies.remove('memberName',{expires:null,path:'/',domain:domainStr})
        Cookies.remove('access_token',{expires:null,path:'/',domain:domainStr})
    }
   

    // sessionStorage.removeItem("vscodemobile")
    // sessionStorage.removeItem("vscode")
    // localStorage.removeItem('memberName')
    // removeLocalToken()
    this.isHeader(true);
    var lett = this;
    document.onkeydown = function() {
        var key = window.event.keyCode;
        if (key == 13) {
          if(lett.showFlag){
              lett.login()
          }else{
              lett.vscodelogin()
          }
        }
    }
  },
  mounted() {
   
  },
  beforeDestroy() {
    this.isHeader(false);
  },
  //路由进入时
  beforeRouteEnter: (to, from, next) => {
    removeToken();
    next((vm) => {
      vm.changeFlag(true);
    });
  },
  //路由离开时
  beforeRouteLeave(to, from, next) {
    if(getToken()!=null){
      this.changeFlag(false);
    }
    next();
  },
  methods: {
// 游客登录
    VisitorLoginFun(){
      // this.mobile='13100001111';
      // this.password='123456';
         Login('13100001111', this.$md5('123456')).then((data) => {
              if(data.data.code===400){
                  this.$message(data.data.msg)
              }else{
                // (0待审核2不通过4待提交      1通过5没有审核通过的门店)
                setToken("bearer " + data.data.access_token);
                setLocalToken("bearer " + data.data.access_token);
                 Cookies.set('memberName', this.mobile);
                localStorage.setItem("refresh_token", data.data.refresh_token)
                let domainStr=document.domain.split('.').slice(-2).join('.')
                Cookies.set('refresh_token',data.data.refresh_token,{path:'/',domain:domainStr});
                // 缓存  会员等级与id及店铺id
                const memberData = {
                  memberLevelId: data.data.member_level_id,
                  memberId: data.data.member_id,
                  memberStoreId: data.data.member_store_id,
                };
                sessionStorage.setItem("memberData", Qs.stringify(memberData));
                localStorage.setItem("memberStoreId",memberData.memberStoreId)
                // alert(data.data.empower_status)
                this.statusFun(data.data.empower_status)
              }
          });
    },
    // 申请授权
    sureFun(){
        userAuth().then(data=>{
            if(data.data.code==200){
              this.$message({
                    message: data.data.msg,
                    type: 'success'
                  });
                  this.centerDialogVisible=false
                  this.AuthorDialogVisible=true
                  this.AuthorTitle="资质授权申请中"
              }else{
                this.$message({
                  message: data.data.msg,
                  type: "warning",
                });
            }
        })
    },
    jumpAgreement(){
       this.$router.push({name:"Agreement"})
    },
     jumpUserAgreement(){
       this.$router.push({name:"UserAgreement"})
    },
    RememberMsgChange(val){
      // 记住账号密码，
        if(val==false){
          this.checkMsgFlag=false
        }else{
          this.checkMsgFlag=true
        }
    },
// eyeBtn
    eyeChange(){
      this.EyeFlag=!this.EyeFlag
      if(this.EyeFlag){
        this.eyeVal="password"
      }else{
        this.eyeVal="text"
      }
    },

  //  handleCheckAllChange(val) {
  //    //  用户协议选择
  //     if(val==false){
  //        this.checkFunFlag=false
  //     }else{
  //       this.checkFunFlag=true
  //     }
  //   },
    validatePhone(){
      if(!this.checkFunFlag){
        return false
      }
      //判断输入的手机号是否合法
      if(this.showFlag){
         if(!this.mobile) {
            this.mobileErrors ="用户账号不能为空"
            this.FunFlag=false
          }else {
            this.mobileErrors =''
            this.FunFlag=true
          }
          // if(!this.mobile) {
          //   this.mobileErrors ="手机号码不能为空"
          //   this.FunFlag=false
          // } else if(!/^1[345678]\d{9}$/.test(this.mobile)) {
          //   this.mobileErrors ="请输入正确是手机号"
          //    this.FunFlag=false
          // } else {
          //   this.mobileErrors =''
          //   this.FunFlag=true
          // }
      }else{
        if(!this.vscodemobile) {
          this.mobileErrors ="手机号码不能为空"
          this.canClick=false
          this.FunFlag=false
        } else if(!/^1[3456789]\d{9}$/.test(this.vscodemobile)) {
          this.mobileErrors ="请输入正确的手机号"
           this.canClick=false
           this.FunFlag=false
        } else {
          this.mobileErrors =''
           this.canClick=true
           this.FunFlag=true
        }
      }
    },
    validateInp(){
      if(!this.checkFunFlag){
        return false
      }
       if(this.showFlag){
            if(!this.password) {
              this.inpErrors ="密码不能为空"
               this.FunFlag=false
            }else{
              this.inpErrors =''
               this.FunFlag=true
            }
       }else{
         this.vscode=this.vscode.replace(/[^\d]/g,'');
           if(!this.vscode) {
              this.inpErrors ="短信验证码不能为空"
               this.FunFlag=false
            }else{
               if(this.vscode.length<6){
                  this.inpErrors ='验证码为六位数字'
                    this.FunFlag=false
              }else{
                  this.inpErrors =''
                   this.FunFlag=true
              }
            }
       }
    },
    jumpRegister(){
      this.$router.push({name:"Register"})
    },

    //短信登录与密码登录切换
    tabFun() {
      this.showFlag = !this.showFlag;
      this.mobileErrors =''
       this.inpErrors =''
    },
    // 倒计时
    countDown() {
      //$refs获取在子组件的属性和方法，子组件需定义ref，child就是在子组件中定义的ref
      if (!this.canClick) return; //改动的是这两行代码
      this.canClick = false;
      this.content = this.totalTime + "s后重新发送";

       sendCode(this.vscodemobile).then((data)=>{
        const h = this.$createElement;
        if(data.data.code==400){
          this.$notify({
            title: '失败',
            message:h('i', { style: 'color: teal'},data.data.msg),
            type: 'error'
          });
        }else{
          this.$notify({
            title: '成功',
            message:h('i', { style: 'color: teal'},data.data.msg),
            type: 'success'
          });
        }

      })
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
    ...mapMutations({
      changeFlag: "changeFlag",
      changeBoolean: "changeBoolean",
      isHeader: "isHeader",
    }),
    //   点击提交 密码验证
    login() {
        Cookies.remove('memberName');
             localStorage.clear();
       sessionStorage.clear()

      
       this.validatePhone()
       this.validateInp()
      if(!this.checkFunFlag){
         this.$message({
              message:"请同意勾选服务协议",
              type: "warning",
            });
        return false
      }

      // let access_token="bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRfaWQiOiIwMDAwMDAiLCJtZW1iZXJfaWQiOjEsInVzZXJfbmFtZSI6IjE4NjM0Mzg1MzI5IiwibWVtYmVyX2xldmVsX2lkIjoxMzA5NDA4NzEzNzk2NjYxMjQ5LCJyZWFsX25hbWUiOiIxODYzNDM4NTMyOSIsImF2YXRhciI6IiIsImNsaWVudF9pZCI6InN3b3JkIiwicm9sZV9uYW1lIjoiIiwibGljZW5zZSI6InBvd2VyZWQgYnkgYmxhZGV4IiwidXNlcl90eXBlIjoid2ViIiwicG9zdF9pZCI6IiIsInVzZXJfaWQiOiIxIiwicm9sZV9pZCI6IiIsInNjb3BlIjpbImFsbCJdLCJuaWNrX25hbWUiOiIxODYzNDM4NTMyOSIsIm9hdXRoX2lkIjoiIiwiaWRfdHlwZSI6MiwibWVtYmVyX3N0b3JlX2lkIjoxMzE2MzAxMzYxNjU2NTY1MTIzLCJleHAiOjE2MDM0NTI1MzMsImRlcHRfaWQiOiIiLCJqdGkiOiI5ZDQzNGM1Zi03YTk5LTQ3ZWQtYTU3My02MmM0MWYwODU0NTIiLCJhY2NvdW50IjoiMTg2MzQzODUzMjkifQ.ybSoXrWJLYidE51SHRvXi8Ktpv28jFUKBvGdZcgejSs"
      // setToken(access_token)
      // this.$router.push({name:"Home"})
      if(this.FunFlag){
          Login(this.mobile, this.$md5(this.password)).then((data) => {
              if(data.data.code===400){
                  this.$message(data.data.msg)
              }else{
                // (0待审核2不通过4待提交      1通过5没有审核通过的门店)
                setToken("bearer " + data.data.access_token);
                setLocalToken("bearer " + data.data.access_token);
                 Cookies.set('memberName', this.mobile);
                localStorage.setItem("refresh_token", data.data.refresh_token)
                let domainStr=document.domain.split('.').slice(-2).join('.')
                Cookies.set('refresh_token',data.data.refresh_token,{path:'/',domain:domainStr});
                // 缓存  会员等级与id及店铺id
                const memberData = {
                  memberLevelId: data.data.member_level_id,
                  memberId: data.data.member_id,
                  memberStoreId: data.data.member_store_id,
                };
                sessionStorage.setItem("memberData", Qs.stringify(memberData));
                localStorage.setItem("memberStoreId",memberData.memberStoreId)

                if(this.checkMsgFlag){
                  // 保存账号密码
                    Cookies.set('wyuserMobile', this.mobile);
                    Cookies.set('wyuserPassword', this.password);
                }else{
                  Cookies.remove('wyuserMobile');
                    Cookies.remove('wyuserPassword');
                }
                
                // alert(data.data.empower_status)
                this.statusFun(data.data.empower_status)
              }
          });
      }

    },
    // 状态判断
    statusFun(empower_status){
      MemberInfo().then((res) => {
        // this.customName=res.data.data.customName;
        if(res.data.data.customPhone==''){
          this.customPhone='400-1008189'
        }else{
          this.customPhone=res.data.data.customPhone
        }
      });
      // 4跳第一个界面 1和5跳正常登录首页 2和0跳第二个界面    (0待审核 2不通过       1通过5没有审核通过的门店)
        if(empower_status===1 || empower_status===5){
                    // 1和5跳正常登录首页
                    this.$router.push({ name: "Home" });
                }else if(empower_status===0){
                    // 0待审核
                    this.AuthorTitle="资质授权申请中"
                    this.AuthorDialogVisible=true
                }else if(empower_status===2){  
                  // 2不通过
                    this.AuthorTitle="资质授权不通过"
                    this.AuthorDialogVisible=true
                }else if(empower_status===4){
                    // 4待提交 
                    // 查询账号首次授权平台
                    firstAuth().then(res=>{
                      console.log(res)
                      this.FirstAuth=res.data.data
                    })
                    this.centerDialogVisible=true;
                    return false;
                }else{
                   this.AuthorTitle="资质授权待复审"
                    this.AuthorDialogVisible=true
                }
    },

    // 点击提交 短信验证
    vscodelogin() {
      Cookies.remove('memberName');
             localStorage.clear();
       sessionStorage.clear()

        this.validatePhone()
        this.validateInp()
        if(!this.checkFunFlag){
         this.$message({
              message:"请同意勾选服务协议",
              type: "warning",
            });
        return false
      }
       if(this.FunFlag){
          vscodeLogin(this.vscodemobile,this.vscode).then((data)=>{
             //----------------------------------- 如未注册先跳注册页，如已经注册，则直接登录
            if(data.data.code===400){
              this.$message(data.data.msg)
              let msg=data.data.msg;

              
              if(msg=="该用户未注册,请去注册"){
                 sessionStorage.setItem("vscodemobile",this.vscodemobile)
                 sessionStorage.setItem("vscode",this.vscode)
                 this.$router.push({name:"Register"})
              }else if(msg=="验证码不正确"){
                this.vscode=''
                this.$refs.vscodeInp.focus();
              }
            }else{
                setToken("bearer " + data.data.access_token);
                setLocalToken("bearer " + data.data.access_token);
                 Cookies.set('memberName', this.vscodemobile);
                localStorage.setItem("refresh_token", data.data.refresh_token)
                let domainStr=document.domain.split('.').slice(-2).join('.')
                Cookies.set('refresh_token',data.data.refresh_token,{path:'/',domain:domainStr});
                // 缓存  会员等级与id及店铺id
                const memberData = {
                  memberLevelId: data.data.member_level_id,
                  memberId: data.data.member_id,
                  memberStoreId: data.data.member_store_id,
                };
                sessionStorage.setItem("memberData", Qs.stringify(memberData));
                localStorage.setItem("memberStoreId",memberData.memberStoreId)
                this.statusFun(data.data.empower_status)
            }
          })
       }
    },
  },
};
</script>
